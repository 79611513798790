<template>
  <v-container>
    <v-row>
      <v-col :cols="12">
        <base-card>
          <div slot="heading" class="title font-weight-light">Create Template</div>
          <template-form :template="template" @submit="onCreate" @cancel="onCancel" />
        </base-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import TemplateForm from '@/components/templates/TemplateForm.vue'
import BaseCard from '@/components/common/BaseCard.vue'

const NEW_TEMPLATE = {
  name: '',
  description: '',
  owner_organization_id: null,
  permission_scope: null,
  host_url: '',
  source_type: null,
  result_schema_type: null,
  result_schema: null,
  file_result_enabled: false,
  enabled: true,
}
export default {
  components: {
    TemplateForm,
    BaseCard,
  },
  data() {
    return {
      template: null,
    }
  },
  created() {
    this.template = JSON.parse(JSON.stringify(NEW_TEMPLATE))
  },
  methods: {
    ...mapActions(['createTemplate']),
    backToTemplateList() {
      this.$router.push({
        name: 'templates',
      })
    },
    async onCreate(newTemplate) {
      try {
        await this.createTemplate(newTemplate)
        this.backToTemplateList()
      } catch (err) {
        console.error(err)
      }
    },
    onCancel() {
      this.backToTemplateList()
    },
  },
}
</script>
